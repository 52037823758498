import { first, omit, pick } from 'lodash';
import { ArdoqError, ExcludeFalsy, isArdoqError } from '@ardoq/common-helpers';
import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIReferenceAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import {
  AddedFields,
  AddedFieldsNewToWorkspace,
  DirtyAttributes,
} from 'appModelStateEdit/types';
import {
  AddedFieldsReducerUtilAccumulator,
  addedFieldsReducerUtil,
  splitDateRangeFieldIntoDateTimeFields,
} from 'appModelStateEdit/propertiesEditor/fieldUtils';
import { getEntityById, readRawValue } from '@ardoq/renderers';
import {
  getModifiedAttributesPerEntity,
  getModifiedSingleEntityAttributes,
} from 'appModelStateEdit/diffUtils';
import {
  CREATE_NEW_FIELD_ATTRIBUTES,
  SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID,
  SAVE_FIELD_ATTRIBUTES,
} from 'appModelStateEdit/consts';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { get } from 'collectionInterface/genericInterfaces';
import { tagInterface } from 'modelInterface/tags/tagInterface';
import * as genericInterfaces from 'modelInterface/genericInterfaces';
import { workspaceInterface } from 'modelInterface/workspaces/workspaceInterface';
import { createCalculatedFieldStoredQuery } from 'calculatedField/helpers';
import { dispatchAction } from '@ardoq/rxbeach';
import { loadCalculatedFieldStoredQuery } from 'search/actions';
import { QueryEditorNamespace } from 'search/QueryEditor/queryEditor$';
import { getFormErrorMessage } from 'appModelStateEdit/formErrorUtils';
import { isScenarioMode } from 'models/utils/scenarioUtils';
import {
  batchCreateReferences,
  batchSaveComponents,
  batchSaveReferences,
} from 'appModelStateEdit/batchUtils';
import { referenceInterface } from 'modelInterface/references/referenceInterface';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { logError } from '@ardoq/logging';
import { fieldOps } from '../models/utils/fieldOps';
import { fieldUtils } from '@ardoq/scope-data';
import { Workspace } from 'aqTypes';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';

const updateFieldAttributes =
  (attributesForUpdatingField: Array<keyof APIFieldAttributes>) =>
  (fieldData: APIFieldAttributes) => {
    return new Promise((resolve, reject) => {
      const existingField = fieldInterface.getByName(fieldData.name, {
        acrossWorkspaces: false,
        includeTemplateFields: true,
      });
      if (!existingField) {
        return;
      }
      const attributes = pick(fieldData, attributesForUpdatingField);
      const field = fieldInterface.get(existingField._id);
      if (!field) {
        return reject(`Field "${fieldData.name}" was not found.`);
      }
      fieldInterface.save(
        field._id,
        { ...field, ...attributes },
        {
          success: () => resolve(existingField),
          error: (_, e) =>
            reject(getFormErrorMessage(e?.responseJSON?.message)),
          wait: true,
        }
      );
    });
  };

export const createFieldFromAttributes =
  (attributesForCreatingField: Array<keyof APIFieldAttributes>) =>
  (field: Partial<APIFieldAttributes>) => {
    return new Promise<APIFieldAttributes>((resolve, reject) => {
      const attributes = pick(field, attributesForCreatingField);
      fieldInterface.create(attributes, {
        success: savedField => {
          resolve(structuredClone(savedField.toJSON()));
        },
        error: (_, e) =>
          reject(
            e?.responseJSON?.errorCode ||
              getFormErrorMessage(e?.responseJSON?.message)
          ),
        wait: true,
      });
    });
  };

export const createOrUpdateFields = (
  fields: APIFieldAttributes[],
  addedFields: AddedFields,
  addedFieldsNewToWorkspace: AddedFieldsNewToWorkspace,
  attributesForUpdatingField: Array<keyof APIFieldAttributes>,
  attributesForCreatingField: Array<keyof APIFieldAttributes>
) => {
  const addedFieldsReducerUtilStartingAccumulator = {
    fieldsToUpdate: [],
    fieldsToCreate: [],
  };
  const { fieldsToUpdate, fieldsToCreate } = Array.from(
    addedFields
  ).reduce<AddedFieldsReducerUtilAccumulator>(
    addedFieldsReducerUtil(fields, addedFieldsNewToWorkspace),
    addedFieldsReducerUtilStartingAccumulator
  );
  return Promise.all([
    ...fieldsToUpdate.map(updateFieldAttributes(attributesForUpdatingField)),
    ...fieldsToCreate.map(
      createFieldFromAttributes(attributesForCreatingField)
    ),
  ]);
};

export const saveEntityAttributes = <T>(
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  _attributes: Partial<T>,
  enhancedScopeData: EnhancedScopeData,
  originalEnhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): Promise<void | ArdoqError | Awaited<T>[]> => {
  const isSingleEntityEdited = entityIDs.length === 1;
  const attributesPerEntity = getModifiedAttributesForSingleOrMultipleEntities(
    isSingleEntityEdited,
    entityType,
    entityIDs,
    _attributes,
    enhancedScopeData,
    originalEnhancedScopeData,
    dirtyAttributes
  );
  if (!isScenarioMode() && entityIDs.length > 1) {
    return entityType === APIEntityType.COMPONENT
      ? batchSaveComponents(Object.values(attributesPerEntity))
      : batchSaveReferences(Object.values(attributesPerEntity));
  }

  return Promise.all(
    entityIDs
      .map(id => get(entityType, id))
      .filter(ExcludeFalsy)
      .map(entity => {
        const attributes = attributesPerEntity[entity._id];
        return new Promise<T>((resolve, reject) => {
          genericInterfaces.save(entityType, entity._id, attributes, {
            success: savedEntity =>
              resolve(structuredClone(savedEntity.toJSON?.())),
            error: (_, e) =>
              reject(getFormErrorMessage(e?.responseJSON?.message)),
            wait: true,
          });
        });
      })
  );
};

const getModifiedAttributesForSingleOrMultipleEntities = <T>(
  isSingleEntityEdited: boolean,
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  _attributes: Partial<T>,
  enhancedScopeData: EnhancedScopeData,
  originalEnhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): Partial<APIComponentAttributes | APIReferenceAttributes> => {
  if (isSingleEntityEdited) {
    const entityId = entityIDs[0];
    return {
      [entityId]: getModifiedSingleEntityAttributes(
        _attributes,
        dirtyAttributes,
        enhancedScopeData
      ),
    };
  }
  return getModifiedAttributesPerEntity(
    entityType,
    entityIDs,
    enhancedScopeData,
    originalEnhancedScopeData,
    dirtyAttributes
  );
};

export const saveFieldAttributes = async (
  fieldId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  let field = fieldUtils.getFieldData(enhancedScopeData, fieldId);
  let newStoredQuery = null;

  if (
    field.calculatedFieldSettings &&
    // when field is changed from not calculated to be calculated, storedQueryId is set to this temp value before saving
    field.calculatedFieldSettings.storedQueryId ===
      SYNTHETIC_PARTIAL_CALCULATED_FIELD_QUERY_ID
  ) {
    const storedQuery = await createCalculatedFieldStoredQuery(field.label);

    if (isArdoqError(storedQuery)) {
      logError(storedQuery);
      return;
    }

    field = {
      ...field,
      calculatedFieldSettings: { storedQueryId: storedQuery._id },
    };
    newStoredQuery = storedQuery;
  }

  const withMappedTypeValues = {
    ...field,
    componentType: field.global ? [] : field.componentType,
    referenceType: field.globalref ? [] : field.referenceType.map(String),
  };
  const fieldsToUpdate = [withMappedTypeValues].flatMap(
    splitDateRangeFieldIntoDateTimeFields
  );
  await Promise.all(
    fieldsToUpdate.map(updateFieldAttributes(SAVE_FIELD_ATTRIBUTES))
  );
  if (newStoredQuery) {
    dispatchAction(
      loadCalculatedFieldStoredQuery({
        storedQuery: newStoredQuery,
        fieldId: field._id,
      }),
      QueryEditorNamespace.CALCULATED_FIELD_QUERY
    );
  }
};

export const saveReferenceTypeAttributes = (
  referenceTypeId: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  modelId: ArdoqId,
  isReferenceTypeCreationFlow?: boolean
) => {
  const referenceType = getEntityById(
    APIEntityType.REFERENCE_TYPE,
    referenceTypeId,
    enhancedScopeData,
    modelId
  );
  if (!referenceType) {
    return;
  }
  const referenceTypeName = referenceInterface.getReferenceTypeName(
    modelId,
    referenceType.id
  );
  if (
    (!isReferenceTypeCreationFlow &&
      referenceType.name !== referenceTypeName &&
      referenceInterface.isReferenceTypeNameUsed(
        referenceType.name,
        modelId
      )) ||
    (isReferenceTypeCreationFlow &&
      referenceInterface.isReferenceTypeNameUsed(referenceType.name, modelId))
  ) {
    return new Promise((_, reject) => {
      reject(
        getFormErrorMessage('Reference type with this name already exists')
      );
    });
  }
  const model = getEntityById(APIEntityType.MODEL, modelId, enhancedScopeData);
  if (!model) {
    return null;
  }
  const updatedModel = {
    ...model,
    referenceTypes: {
      ...model.referenceTypes,
      [referenceType.id]: referenceType,
    },
  };
  return new Promise((resolve, reject) => {
    genericInterfaces.save(APIEntityType.MODEL, modelId, updatedModel, {
      success: savedModel => {
        resolve(structuredClone(savedModel.toJSON()));
      },
      error: (_, e) => reject(getFormErrorMessage(e?.responseJSON?.message)),
      wait: true,
    });
  });
};

export const saveWorkspaceAttributes = (
  workspaceId: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const workspace = enhancedScopeData.workspacesById[workspaceId];
  return new Promise((resolve, reject) => {
    workspaceInterface.save(workspaceId, workspace, {
      success: (savedWorkspace: Workspace) =>
        resolve(structuredClone(savedWorkspace.toJSON())),
      error: (_: any, e: { responseJSON?: { message: string } }) =>
        reject(getFormErrorMessage(e?.responseJSON?.message)),
      wait: true,
    });
  });
};

export const createField = async (
  tempId: string,
  enhancedScopeData: EnhancedScopeData
): Promise<void> => {
  const field = enhancedScopeData.fieldsById[tempId];
  if (!field) {
    return;
  }

  if (hasExistingField(field.label, enhancedScopeData)) {
    throw new Error(`Field "${field.label}" already exists.`);
  }

  if (fieldOps.isCalculatedField(field)) {
    return createCalculatedField(field);
  }

  if (fieldOps.isLocallyDerivedField(field)) {
    return createLocallyDerivedField(field);
  }

  const fieldsToCreate = [field].flatMap(splitDateRangeFieldIntoDateTimeFields);
  const creatorFn = createFieldFromAttributes(CREATE_NEW_FIELD_ATTRIBUTES);

  await Promise.all(fieldsToCreate.map(creatorFn));
};

const hasExistingField = (
  label: string,
  enhancedScopeData: EnhancedScopeData
): boolean =>
  fieldInterface.getIsExistingFieldInWorkspace(
    label,
    enhancedScopeData.workspaces[0]._id
  );

const createCalculatedField = async (
  field: APIFieldAttributes
): Promise<void> => {
  const storedQuery = await createCalculatedFieldStoredQuery(field.label);

  if (isArdoqError(storedQuery)) {
    logError(storedQuery);
    return;
  }

  const attributes: APIFieldAttributes = {
    ...field,
    type: field.type,
    calculatedFieldSettings: {
      storedQueryId: storedQuery._id,
    },
  };

  const createdField = await createFieldFromAttributes(
    CREATE_NEW_FIELD_ATTRIBUTES
  )(attributes);

  dispatchAction(
    loadCalculatedFieldStoredQuery({ storedQuery, fieldId: createdField._id }),
    QueryEditorNamespace.CALCULATED_FIELD_QUERY
  );
};

const createLocallyDerivedField = async (
  field: APIFieldAttributes
): Promise<void> => {
  const attributes: APIFieldAttributes = {
    ...field,
    type: field.type,
  };

  await createFieldFromAttributes(CREATE_NEW_FIELD_ATTRIBUTES)(attributes);
};

const persistAttributes = <T>(
  entityType: APIEntityType,
  attributes: Partial<T>
) =>
  new Promise<T>((resolve, reject) => {
    // _id is a temp uuid
    const attributesWithoutId = omit(attributes, '_id');
    genericInterfaces.create(entityType, attributesWithoutId, {
      success: entity => {
        resolve(structuredClone(entity.toJSON()));
      },
      error: (_, e) => reject(getFormErrorMessage(e?.responseJSON?.message)),
      wait: true,
    });
  });

const createReferencesSequentially = (
  referenceAttributes: Partial<APIReferenceAttributes>[]
) =>
  Promise.all(
    referenceAttributes.reduce<Promise<APIReferenceAttributes>[]>(
      (promises, attributes) => {
        if (!promises.length) {
          return [persistAttributes(APIEntityType.REFERENCE, attributes)];
        }
        return [
          ...promises,
          promises[promises.length - 1].then(() =>
            persistAttributes(APIEntityType.REFERENCE, attributes)
          ),
        ];
      },
      []
    )
  );

export const createEntitiesFromAttributes = <T>(
  entityType: APIEntityType,
  entityAttributes: Partial<T>[]
): Promise<ArdoqError | APIReferenceAttributes[] | Awaited<T>[]> => {
  const isBatchCreatingReferences =
    entityAttributes.length > 1 && entityType === APIEntityType.REFERENCE;
  if (isBatchCreatingReferences) {
    if (isScenarioMode()) {
      return createReferencesSequentially(entityAttributes);
    }
    return batchCreateReferences(entityAttributes);
  }
  return Promise.all(
    entityAttributes.map(attributes =>
      persistAttributes(entityType, attributes)
    )
  );
};

const getTagRootWorkspace = (
  contextWorkspaceId: ArdoqId,
  entityWorkspaceIDs: ArdoqId[]
) => {
  if (contextWorkspaceId && !entityWorkspaceIDs.includes(contextWorkspaceId)) {
    return first(entityWorkspaceIDs);
  }
};

export const createOrUpdateTags = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  originalEnhancedScopeData: EnhancedScopeData,
  contextWorkspaceId: ArdoqId
) => {
  if (!enhancedScopeData) {
    return;
  }

  const entityWorkspaceIDs = entityIDs.map(entityID =>
    readRawValue(entityType, entityID, 'rootWorkspace', enhancedScopeData)
  );
  const rootWorkspaceId = getTagRootWorkspace(
    contextWorkspaceId,
    entityWorkspaceIDs
  );

  const tagsToAdd = entityIDs.map(entityId =>
    enhancedScopeDataOperations.getTagsToAdd(
      entityId,
      entityType,
      originalEnhancedScopeData,
      enhancedScopeData
    )
  );

  const tagsToRemove = entityIDs.map(entityId =>
    enhancedScopeDataOperations.getTagsToRemove(
      entityId,
      entityType,
      originalEnhancedScopeData,
      enhancedScopeData
    )
  );

  for (const { entityId, tagNames } of tagsToAdd) {
    for (const tagName of tagNames) {
      tagInterface.addTag(tagName, entityType, entityId, rootWorkspaceId);
    }
  }

  for (const { entityId, tagNames } of tagsToRemove) {
    for (const tagName of tagNames) {
      tagInterface.removeTag(tagName, entityType, entityId);
    }
  }
};
